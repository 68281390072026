import {
  GET_SESSIONS,
  GET_SESSION_BY_ID,
  REMOVE_ATTACHMENT,
  UPLOAD_ATTACHMENT_SUCCESS,
  SET_SESSIONS,
  SET_CURRENT_SESSION,
  UPLOAD_ATTACHMENT,
  REMOVE_ATTACHMENT_SUCCESS,
  CREATE_SESSION,
  CLEAR_ATTACHMENTS,
  ADD_ATTACHMENTS,
  EDIT_SESSION,
  REMOVE_LINK,
  CHANGE_SESSION_STATUS,
  DELETE_SESSION,
  CHANGE_STATUS_TAB,
  STOP_LOADING_SESSION,
  SET_SESSION_MATERIALS,
  REMOVE_SESSION_MATERIAL,
} from "redux/constants";

export const stopLoadingSession = (payload) => ({
  type: STOP_LOADING_SESSION,
  payload,
});

export const getSessions = (payload) => ({
  type: GET_SESSIONS,
  payload,
});

export const setSessions = (payload) => ({
  type: SET_SESSIONS,
  payload,
});

export const getSessionById = (payload) => ({
  type: GET_SESSION_BY_ID,
  payload,
});

export const setCurrentSession = (payload) => ({
  type: SET_CURRENT_SESSION,
  payload,
});

export const setSessionMaterials = (payload) => ({
  type: SET_SESSION_MATERIALS,
  payload,
});

export const removeSessionMaterial = (payload) => ({
  type: REMOVE_SESSION_MATERIAL,
  payload,
});

export const uploadAttachment = ({ payload, meta }) => ({
  type: UPLOAD_ATTACHMENT,
  payload,
  meta,
});

export const uploadAttachmentSuccess = (payload) => ({
  type: UPLOAD_ATTACHMENT_SUCCESS,
  payload,
});

export const removeAttachment = ({ payload, meta }) => ({
  type: REMOVE_ATTACHMENT,
  payload,
  meta,
});

export const removeAttachmentSuccess = (payload) => ({
  type: REMOVE_ATTACHMENT_SUCCESS,
  payload,
});

export const createSession = ({ payload, meta }) => ({
  type: CREATE_SESSION,
  payload,
  meta,
});

export const clearAttachments = (payload) => ({
  type: CLEAR_ATTACHMENTS,
  payload,
});

export const addAttachments = (payload) => ({
  type: ADD_ATTACHMENTS,
  payload,
});

export const editSession = ({ payload, meta }) => ({
  type: EDIT_SESSION,
  payload,
  meta,
});

export const removeLink = (payload) => ({
  type: REMOVE_LINK,
  payload,
});

export const changeSessionStatus = ({ payload, meta }) => ({
  type: CHANGE_SESSION_STATUS,
  payload,
  meta,
});

export const deleteSession = ({ payload, meta }) => ({
  type: DELETE_SESSION,
  payload,
  meta,
});

export const changeStatusTab = (payload) => ({
  type: CHANGE_STATUS_TAB,
  payload,
});
