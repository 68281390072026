export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_MESSAGEBOARD_PAGE_SIZE = 5;
export const MESSAGE_BOARD_SIZE_OPTIONS = [5, 10, 20];
export const DEFAULT_CALENDAR_LIST_LIMIT = 20;

export const VALID_PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const MODE_ADD = "ADD";
export const MODE_EDIT = "EDIT";

export const VIEW_GRID = "GRID";
export const VIEW_LIST = "LIST";

export const SKIPPED = "skipped";
export const UPCOMING = "upcoming";
export const ACTIVE = "active";
export const COMPLETED = "completed";
export const ALL_REGIONS_TEXT = "All Regions";

export const SUCCESS_COLOR = "#389E0D";

export const STUDENT_WORKSHEET_TYPE = {
  key: "studentWorksheet",
  value: "studentWorksheet",
  text: "Student Worksheet",
};

export const STUDENT_HANDOUT_TYPE = {
  key: "studentHandout",
  value: "studentHandout",
  text: "Student Handout",
};

export const POD_TYPE = {
  key: "pod",
  value: "pod",
  text: "Pod",
};

export const PARENT_TYPE = {
  key: "parent",
  value: "parent",
  text: "Parent",
};

export const STAFF_TYPE = {
  key: "staff",
  value: "staff",
  text: "Staff",
};

export const STUDENT_TYPE = {
  key: "student",
  value: "student",
  text: "Student",
};

export const COACH_TYPE = {
  key: "coach",
  value: "coach",
  text: "Coach",
};

export const ADMIN_TYPE = {
  key: "admin",
  value: "admin",
  text: "Admin",
};

export const ARCHIVED_TYPE = {
  key: "archived",
  value: "archived",
  text: "Archived",
};

export const PUBLISHED_TYPE = {
  key: "published",
  value: "published",
  text: "Published",
};

export const DRAFTS_TYPE = {
  key: "drafts",
  value: "drafts",
  text: "Drafts",
};

export const ACTIVE_TYPE = {
  key: "active",
  value: "active",
  text: "Active",
};

export const PENDING_TYPE = {
  key: "pending",
  value: "pending",
  text: "Pending",
};

export const COMPLETED_TYPE = {
  key: "completed",
  value: "completed",
  text: "Completed",
};

export const ATTENDANCE_TYPE = {
  key: "attendance",
  value: "attendance",
  text: "Attendance",
};

export const FITNESS_TYPE = {
  key: "fitness",
  value: "fitness",
  text: "Fitness",
};

export const CHARACTER_STRENGTHS_TYPE = {
  key: "characterStrengths",
  value: "characterStrengths",
  text: "Character Strengths",
};

export const JOURNAL_TYPE = {
  key: "journal",
  value: "journal",
  text: "Journal",
};

export const CHALLENGES_TYPE = {
  key: "challenges",
  value: "challenges",
  text: "Challenges",
};

export const WEEKLY_CHALLENGE_TYPE = {
  key: "weeklyChallenge",
  value: "weeklyChallenge",
  text: "Weekly Challenge",
};

export const MATERIALS_TYPE = {
  key: "materials",
  value: "materials",
  text: "Materials",
};

export const SUBMISSIONS_TYPE = {
  key: "submissions",
  value: "submissions",
  text: "Submissions",
};

export const SESSION_NUMBER_TYPE = {
  key: "sessionNumber",
  value: "sessionNumber",
  text: "Session Number",
};

export const BASIC_STAFF_USER_ROLES = [
  { key: "director", value: "director", text: "Director" },
  COACH_TYPE,
];

export const ALL_STAFF_USER_ROLES = [...BASIC_STAFF_USER_ROLES, ADMIN_TYPE];

export const USER_TYPES = [STUDENT_TYPE, COACH_TYPE];

export const SUBMITTED = "submitted";
export const DRAFT = "draft";

export const SESSION_STATUSES = [PUBLISHED_TYPE, DRAFTS_TYPE, ARCHIVED_TYPE];

export const POD_SESSION_STATUSES = [ACTIVE_TYPE, PENDING_TYPE, COMPLETED_TYPE];

export const STAFF_STATUSES = [
  ACTIVE_TYPE,
  ARCHIVED_TYPE,
  { key: "pending", value: "pending", text: "Pending Invite" },
];

export const POD_STATUSES = [ACTIVE_TYPE, ARCHIVED_TYPE];

export const MATERIAL_TYPES = [
  { key: COACH_TYPE.key, value: COACH_TYPE.value, text: "Preparation" },
  STUDENT_TYPE,
  STUDENT_WORKSHEET_TYPE,
  STUDENT_HANDOUT_TYPE,
];

export const REGIONS = [
  { key: "bc", value: "bc", text: "British Columbia" },
  { key: "ab", value: "ab", text: "Alberta" },
  { key: "sk", value: "sk", text: "Saskatchewan" },
  { key: "mb", value: "mb", text: "Manitoba" },
  { key: "on", value: "on", text: "Ontario" },
  { key: "qc", value: "qc", text: "Quebec" },
  { key: "pe", value: "pe", text: "Prince Edward Island" },
  { key: "ns", value: "ns", text: "Nova Scotia" },
  { key: "nb", value: "nb", text: "New Brunswick" },
  { key: "nl", value: "nl", text: "Newfoundland & Labrador" },
];

export const DAYS_OF_WEEK = [
  { key: "sun", value: "sun", text: "Sun", fullText: "Sunday" },
  { key: "mon", value: "mon", text: "Mon", fullText: "Monday" },
  { key: "tues", value: "tues", text: "Tues", fullText: "Tuesday" },
  { key: "wed", value: "wed", text: "Wed", fullText: "Wednesday" },
  { key: "thurs", value: "thurs", text: "Thurs", fullText: "Thursday" },
  { key: "fri", value: "fri", text: "Fri", fullText: "Friday" },
  { key: "sat", value: "sat", text: "Sat", fullText: "Saturday" },
];

export const WEEK_NUMBERS = new Array(40).fill().map((_, index) => ({
  key: `${index + 1}`,
  value: index + 1,
  text: `Week ${index + 1}`,
}));

export const SESSION_NUMBERS = new Array(2).fill().map((_, index) => ({
  key: `${index + 1}`,
  value: index + 1,
  text: `S-${index + 1}`,
}));

export const GRADES = [
  { key: "1", value: "1", text: "1" },
  { key: "2", value: "2", text: "2" },
  { key: "3", value: "3", text: "3" },
  { key: "4", value: "4", text: "4" },
  { key: "5", value: "5", text: "5" },
  { key: "6", value: "6", text: "6" },
  { key: "7", value: "7", text: "7" },
  { key: "8", value: "8", text: "8" },
];

export const CHALLENGES = "challenges";
export const JOURNAL = "journal";

export const SCORING_CATEGORIES = [
  ATTENDANCE_TYPE,
  FITNESS_TYPE,
  CHARACTER_STRENGTHS_TYPE,
];

export const SCORING_CATEGORIES_SESSION_TWO = [JOURNAL_TYPE, CHALLENGES_TYPE];

export const ATTENDANCE_TYPES = [
  ...SCORING_CATEGORIES,
  JOURNAL_TYPE,
  CHALLENGES_TYPE,
];

export const POD_ATTACHMENT_TYPES = [MATERIALS_TYPE, SUBMISSIONS_TYPE];

export const ATTENDANCE_YEAR_TYPES = [
  { key: "2021", value: "2021", text: "21/22" },
  { key: "2022", value: "2022", text: "22/23" },
  { key: "2023", value: "2023", text: "23/24" },
  { key: "2024", value: "2024", text: "24/25" },
];

export const STUDENT_AGES = [
  { key: "5", value: "5", text: "5" },
  { key: "6", value: "6", text: "6" },
  { key: "7", value: "7", text: "7" },
  { key: "8", value: "8", text: "8" },
  { key: "9", value: "9", text: "9" },
  { key: "10", value: "10", text: "10" },
  { key: "11", value: "11", text: "11" },
  { key: "12", value: "12", text: "12" },
];

export const STUDENT_GRADES = [
  { key: "1", value: "1", text: "1" },
  { key: "2", value: "2", text: "2" },
  { key: "3", value: "3", text: "3" },
  { key: "4", value: "4", text: "4" },
  { key: "5", value: "5", text: "5" },
  { key: "6", value: "6", text: "6" },
  { key: "7", value: "7", text: "7" },
  { key: "8", value: "8", text: "8" },
];

export const PHONE_TYPES = [
  { key: "Home", value: "Home", text: "Home" },
  { key: "Cell", value: "Cell", text: "Cell" },
  { key: "Business", value: "Business", text: "Business" },
  { key: "Other", value: "Other", text: "Other" },
];

export const PHONE_INPUT_RULES = {
  required: true,
  message: "Please use only numbers.",
  pattern: new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/),
};
