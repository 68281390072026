import {
  AUTHENTICATE_ADMIN,
  AUTHENTICATE_ADMIN_SUCCESS,
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  HIDE_AUTH_MESSAGE,
  LOGIN_AS_USER,
  LOGIN_AS_USER_SUCCESS,
  RESET,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SHOW_AUTH_MESSAGE,
  SIGNOUT,
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_SUCCESS,
} from "../constants";

export const reset = () => ({
  type: RESET,
});

export const authenticateAdmin = (values) => ({
  type: AUTHENTICATE_ADMIN,
  payload: values,
});

export const authenticateAdminSuccess = ({ token, role }) => ({
  type: AUTHENTICATE_ADMIN_SUCCESS,
  payload: { token, role },
});

export const authenticateUser = ({ values, role }) => ({
  type: AUTHENTICATE_USER,
  payload: { values, role },
});

export const authenticateUserSuccess = ({ token, role }) => ({
  type: AUTHENTICATE_USER_SUCCESS,
  payload: { token, role },
});

export const signOut = () => ({
  type: SIGNOUT,
});

export const showAuthMessage = ({ messageContent, messageType }) => ({
  type: SHOW_AUTH_MESSAGE,
  payload: { messageContent, messageType },
});

export const hideAuthMessage = () => ({
  type: HIDE_AUTH_MESSAGE,
});

export const verifyAccount = (payload) => ({
  type: VERIFY_ACCOUNT,
  payload,
});

export const verifyAccountSuccess = (payload) => ({
  type: VERIFY_ACCOUNT_SUCCESS,
  payload,
});

export const forgotPassword = (payload) => ({
  type: FORGOT_PASSWORD,
  payload,
});

export const forgotPasswordSuccess = (payload) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const resetPassword = (payload) => ({
  type: RESET_PASSWORD,
  payload,
});

export const resetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const loginAsUser = (payload) => ({
  type: LOGIN_AS_USER,
  payload,
});

export const loginAsUserSuccess = (payload) => ({
  type: LOGIN_AS_USER_SUCCESS,
  payload,
});
