import {
  CREATE_EMERGENCY_CONTACT,
  UPDATE_EMERGENCY_CONTACT,
} from "redux/constants";

export const createEmergencyContact = (payload) => ({
  type: CREATE_EMERGENCY_CONTACT,
  payload,
});

export const updateEmergencyContact = (payload) => ({
  type: UPDATE_EMERGENCY_CONTACT,
  payload,
});
